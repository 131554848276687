import Box from "@/elements/Box";
import Button from "@/elements/Button";
import Typography from "@/elements/Typography";
import Card from "@mui/material/Card";
import PosizioneDispositivo from "../PosizioneDispositivo";
import ProprietaMisurataBar from "../ProprietaMisurataBar";
import { getDispositivoTypeLabel } from "@/utils/dispositivoUtils";
import {
  DispositivoDto,
  getGetDispositiviQueryKey,
  ProprietaMisurataDto,
  useEliminaDispositivo,
} from "@/api";
import { Link } from "react-router-dom";
import { useAuth } from "@/context/useAuth";
import { useConfirmationDialog } from "@/utils/useConfirmationDialog";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { Icon } from "@mui/material";

function DispositivoCard({
  dispositivo,
  proprietaMisurate,
  showPosizione,
}: {
  dispositivo: DispositivoDto;
  proprietaMisurate: ProprietaMisurataDto[];
  showPosizione: boolean;
}) {
  const { hasPermission } = useAuth();
  const { askConfirmation, confirmationDialog } = useConfirmationDialog();

  const { mutateAsync } = useEliminaDispositivo({
    mutation: {
      onSuccess: () => {
        toast.success("Dispositivo eliminato!");
      },
      onError: () => {
        toast.error("Errore nella richiesta");
      },
    },
  });

  const queryClient = useQueryClient();

  const deleteDispositivo = useCallback(
    async (dispositivo: DispositivoDto) => {
      try {
        await mutateAsync({ id: dispositivo.id });
        queryClient.invalidateQueries({
          queryKey: getGetDispositiviQueryKey(),
        });
      } catch (err) {}
    },
    [mutateAsync, queryClient]
  );

  const typeLabel = getDispositivoTypeLabel(dispositivo.type);

  const { posizione } = dispositivo;

  return (
    <Card sx={{ minWidth: 300, maxWidth: 400 }}>
      {confirmationDialog}
      <Box p={2} flex={1}>
        <Box display="flex" flexDirection="column" height="100%">
          <Typography variant="body2" color="text" fontWeight="medium">
            {typeLabel}
          </Typography>

          <Typography variant="h5" fontWeight="bold">
            {dispositivo.matricola}
          </Typography>

          {showPosizione && (
            <Box sx={{ minHeight: 18 }}>
              {posizione && <PosizioneDispositivo posizione={posizione} />}
            </Box>
          )}

          <Box mt={2} mb={0}>
            {proprietaMisurate.map((proprietaMisurata, index) => {
              return (
                <ProprietaMisurataBar
                  key={index}
                  proprietaMisurata={proprietaMisurata}
                  value={proprietaMisurata.lastValue ?? null}
                />
              );
            })}
          </Box>

          <Box
            mt="auto"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant="textArrow"
              color="text"
              LinkComponent={Link}
              to={`/dispositivo/${dispositivo.id}`}
            >
              Vedi dettaglio
            </Button>

            {hasPermission("DispositivoDelete") && (
              <Button
                variant="text"
                size="small"
                color="error"
                startIcon={<Icon>delete_outlined</Icon>}
                sx={{ pb: 0 }}
                onClick={() => {
                  askConfirmation({
                    title: "Elimina dispositivo",
                    message: `Sei sicuro di voler eliminare il dispositivo ${dispositivo.matricola} (${dispositivo.modello})?`,
                    okColor: "error",
                    onConfirm: () => {
                      return deleteDispositivo(dispositivo);
                    },
                  });
                }}
              >
                Elimina
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default DispositivoCard;
