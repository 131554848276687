import Box from "@/elements/Box";
import { Card, Grid } from "@mui/material";
import { CentraleDto, useGetCentrale, useGetCentraleByQRCode } from "@/api";
import Loading from "@/components/Loading";
import LoadingError from "@/components/LoadingError";
import { useParams } from "react-router-dom";
import Typography from "@/elements/Typography";
import CentraleOlioAttualeCard from "@/components/CentraleDetailsPage/CentraleOlioAttualeCard";
import AlloggiamentiFiltroCard from "@/components/CentraleDetailsPage/AlloggiamentiFiltroCard";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Button from "@/elements/Button";
import React, { useState } from "react";
import GestisciReminderDialog from "@/components/CentraleDetailsPage/GestisciReminderDialog";
import AnalisiTableCard from "@/components/CentraleDetailsPage/AnalisiTableCard";
import BonificheTableCard from "@/components/CentraleDetailsPage/BonificheTable";
import { useAuth } from "@/context/useAuth";
import CentraleDialog from "@/components/CentraleDialog";
import MacchinariSensoriTable from "@/components/CentraleDetailsPage/MacchinariSensoriTable";
import FiltrazioniTableCard from "@/components/CentraleDetailsPage/FiltrazioniTable";
import CentraleImmaginiCard from "./CentraleImmaginiCard";
import QRCodeDialog from "../QRCodeDialog";

export function CentraleDetails({ centrale }: { centrale: CentraleDto }) {
  const { hasPermission } = useAuth();
  const [reminderDialogOpen, setReminderDialogOpen] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [qrcodeOpen, setQRCodeOpen] = useState(false);

  return (
    <>
      <Box>
        <Grid container>
          <Grid item p={1} xs={12} mb={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              mr={2}
              flexWrap="wrap"
            >
              <Box display="flex" flexDirection="column">
                <Typography variant="h4">Centrale "{centrale.nome}"</Typography>
              </Box>

              <Box display="flex" flexDirection="row" gap={2} flexWrap="wrap">
                <Box>
                  <Button
                    onClick={() => {
                      setQRCodeOpen(true);
                    }}
                  >
                    QR Code
                  </Button>
                </Box>
                {hasPermission("CentraleUpdate") && (
                  <Box>
                    <Button
                      onClick={() => {
                        setUpdateDialogOpen(true);
                      }}
                    >
                      Modifica centrale
                    </Button>
                  </Box>
                )}

                {hasPermission("CentraleUpdateReminders") && (
                  <Box>
                    <Button
                      startIcon={<NotificationsIcon />}
                      onClick={() => {
                        setReminderDialogOpen(true);
                      }}
                    >
                      Gestisci Reminder
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>

          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            gap={2}
            flex={1}
          >
            <Box
              display="flex"
              flexDirection="column"
              flexWrap="wrap"
              gap={2}
              sx={{
                minWidth: {
                  xs: "100%",
                  md: 300,
                },
                maxWidth: 500,
              }}
            >
              <CentraleOlioAttualeCard
                centrale={centrale}
                olio={centrale.olioAttuale ?? null}
              />

              <CentraleImmaginiCard centrale={centrale} />
            </Box>

            <AlloggiamentiFiltroCard
              centrale={centrale}
              alloggiamentiFiltro={centrale.alloggiamentiFiltro ?? []}
            />
          </Box>

          <Grid item p={1} xs={12}>
            <MacchinariSensoriTable centrale={centrale} />
          </Grid>

          <Grid item p={1} xs={12}>
            <AnalisiTableCard
              centrale={centrale}
              analisi={centrale.analisi || []}
              canEdit={hasPermission("CentraleUpdateAnalisi")}
            />
          </Grid>

          <Grid item p={1} xs={12}>
            <FiltrazioniTableCard
              centrale={centrale}
              filtrazioni={centrale.filtrazioni || []}
              canEdit={hasPermission("CentraleUpdateFiltrazioni")}
            />
          </Grid>

          <Grid item p={1} xs={12}>
            <BonificheTableCard
              centrale={centrale}
              bonifiche={centrale.bonifiche || []}
              canEdit={hasPermission("CentraleUpdateBonifica")}
            />
          </Grid>
        </Grid>
      </Box>

      <GestisciReminderDialog
        centrale={centrale}
        open={reminderDialogOpen}
        onClose={() => {
          setReminderDialogOpen(false);
        }}
      />
      <CentraleDialog
        centrale={centrale}
        open={updateDialogOpen}
        onClose={() => {
          setUpdateDialogOpen(false);
        }}
      />
      <QRCodeDialog
        centrale={centrale}
        open={qrcodeOpen}
        onClose={() => {
          setQRCodeOpen(false);
        }}
      />
    </>
  );
}

export function CentraleDetailsPage() {
  const params = useParams();
  const id = parseInt(params.id || "");

  const { isLoading, error, data } = useGetCentrale(id);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <LoadingError error={error} />;
  }

  return <CentraleDetails centrale={data.data} />;
}

export function CentraleDetailsPageQRCode() {
  const params = useParams();
  const id = params.id || "";

  const { isLoading, error, data } = useGetCentraleByQRCode(id);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return (
      <Box>
        <Typography color="secondary">
          C'è un problema con il QR code che hai scansionato :-(
        </Typography>
      </Box>
    );
  }

  return <CentraleDetails centrale={data.data} />;
}
