import logoGatti from "@/assets/logo_gatti_qr.png";
import { Box, IconButton, Tooltip } from "@mui/material";
import { QRCodeSVG, QRCodeCanvas } from "qrcode.react";
import { useRef } from "react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";

const pngSize = 512;

export function GattiQrCode({
  value,
  size,
  fileName,
}: {
  value: string;
  size: number;
  fileName: string;
}) {
  const divRef = useRef<HTMLCanvasElement | undefined>();

  function copyToClipboard() {
    const canvas = divRef?.current?.querySelector("canvas") as
      | HTMLCanvasElement
      | undefined;
    if (!canvas) {
      return;
    }

    canvas.toBlob(function (blob) {
      if (!blob) {
        return;
      }

      const item = new ClipboardItem({ "image/png": blob });
      navigator.clipboard
        .write([item])
        .then(function () {
          toast.success("Immagine QR code copiata negli appunti");
        })
        .catch(function (error) {
          console.error("Failed to copy image to clipboard:", error);
        });
    });
  }

  function downloadAsPng() {
    const canvas = divRef?.current?.querySelector("canvas") as
      | HTMLCanvasElement
      | undefined;
    if (!canvas) {
      return;
    }

    canvas.toBlob(function (blob) {
      if (!blob) {
        return;
      }

      const anchor = document.createElement("a");
      anchor.href = canvas.toDataURL();
      anchor.download = `${fileName}.png`;
      anchor.click();
    });
  }

  return (
    <>
      <div style={{ position: "relative" }}>
        <QRCodeSVG
          value={value}
          size={size}
          level="M"
          imageSettings={{
            src: logoGatti.toString(),
            width: size * 0.5,
            height: size * 0.15,
            excavate: true,
          }}
        />

        <Box
          sx={{
            position: "absolute",
            right: 8,
            bottom: 8,
            padding: 0.5,
            borderRadius: 2,
          }}
          style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
        >
          <Tooltip title="Scarica immagine">
            <IconButton size="small" color="primary" onClick={downloadAsPng}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Copia immagine">
            <IconButton size="small" color="primary" onClick={copyToClipboard}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </div>

      <div style={{ display: "none" }} ref={divRef as any}>
        <QRCodeCanvas
          value={value}
          size={pngSize}
          level="M"
          imageSettings={{
            src: logoGatti.toString(),
            width: pngSize * 0.5,
            height: pngSize * 0.15,
            excavate: true,
          }}
        />
      </div>
    </>
  );
}
