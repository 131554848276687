import {
  AlloggiamentoFiltroDto,
  FiltroDto,
  useGetAlloggiamentoFiltro,
} from "@/api";
import Loading from "@/components/Loading";
import Button from "@/elements/Button";
import Table from "@/elements/Table";
import { getFiltroTypeHelper } from "@/utils/filtroTypeUtils";
import { Box, Card, Grid, Typography } from "@mui/material";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

const columnHelper = createColumnHelper<FiltroDto>();

export function AlloggiamentoFiltro({
  alloggiamentoFiltro,
}: {
  alloggiamentoFiltro: AlloggiamentoFiltroDto;
}) {
  const navigate = useNavigate();
  const hasFiltro = !!alloggiamentoFiltro.filtroAttuale?.modelloFiltro;

  const columns = useMemo<ColumnDef<FiltroDto, any>[]>(() => {
    return [
      columnHelper.accessor(
        (row) => moment(row.montatoIl).format("DD/MM/YYYY"),
        {
          header: "Montato il",
        }
      ),

      columnHelper.accessor(
        (row) => `${row.modelloFiltro?.nome} - ${row.modelloFiltro?.marca}`,
        {
          header: "Modello filtro",
        }
      ),

      columnHelper.accessor(
        (row) =>
          row.daSostituireDopoIl
            ? moment(row.daSostituireDopoIl).format("DD/MM/YYYY")
            : "",
        {
          header: "Montato il",
        }
      ),
    ];
  }, []);

  if (alloggiamentoFiltro) {
    return (
      <>
        <Grid container columnSpacing={1} rowSpacing={2} mt={2}>
          <Grid item sm={12} lg={6} sx={{ width: "100%" }}>
            <Card>
              <Box display="flex" flexDirection={"column"} gap={2} p={1}>
                <Box
                  display="flex"
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography variant="h4">
                    Centrale{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {alloggiamentoFiltro.centrale?.nome}
                    </span>
                  </Typography>
                  <Typography variant="h4">
                    Alloggiamento{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {alloggiamentoFiltro.nome}
                    </span>
                  </Typography>
                </Box>
                <Box
                  display={"grid"}
                  width={"100%"}
                  gap={"1px"}
                  gridTemplateColumns={"1fr 1fr"}
                >
                  {/* <Typography
                    variant="h5"
                    p={1}
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Alloggiamento:
                  </Typography>
                  <Typography variant="h5" p={1} fontWeight={"regular"}>
                    <span style={{ fontWeight: "bold" }}>
                      {alloggiamentoFiltro.nome}{" "}
                    </span>
                    (
                    {getFiltroTypeHelper(alloggiamentoFiltro.filtroType)?.label}
                    )
                  </Typography> */}

                  <Typography
                    variant="h5"
                    p={1}
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Tipo:
                  </Typography>
                  <Typography variant="h5" p={1} fontWeight={"regular"}>
                    {getFiltroTypeHelper(alloggiamentoFiltro.filtroType)?.label}
                  </Typography>

                  <Typography variant="h5" p={1}>
                    Filtro:
                  </Typography>
                  <Typography
                    variant="h5"
                    p={1}
                    fontWeight={"regular"}
                    fontStyle={
                      alloggiamentoFiltro.filtroAttuale?.modelloFiltro
                        ? "normal"
                        : "italic"
                    }
                  >
                    {hasFiltro
                      ? `${alloggiamentoFiltro?.filtroAttuale?.modelloFiltro?.nome} - ${alloggiamentoFiltro?.filtroAttuale?.modelloFiltro?.marca}`
                      : "Nessun filtro montato"}
                  </Typography>

                  <Typography variant="h5" p={1}>
                    Montato il:
                  </Typography>
                  <Typography variant="h5" p={1} fontWeight={"regular"}>
                    {hasFiltro
                      ? moment(
                          alloggiamentoFiltro.filtroAttuale?.montatoIl
                        ).format("L")
                      : "-"}
                  </Typography>

                  <Typography variant="h5" p={1}>
                    Da sostituire dopo il:
                  </Typography>
                  <Typography variant="h5" p={1} fontWeight={"regular"}>
                    {hasFiltro
                      ? moment(
                          alloggiamentoFiltro.filtroAttuale?.daSostituireDopoIl
                        ).format("L")
                      : "-"}
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>

          <Grid item sm={12} lg={6} sx={{ width: "100%" }}>
            <Card>
              <Box display="flex" flexDirection={"column"} gap={2} p={1}>
                <Typography variant="h4" fontWeight={"bold"}>
                  Storico
                </Typography>

                <Table
                  columns={columns}
                  data={alloggiamentoFiltro.storicoFiltri}
                  noRowsText="Storico filtri assente"
                  mt={1}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>

        <Box
          display="flex"
          flexDirection={"column"}
          alignItems={"center"}
          sx={{ my: 4 }}
        >
          <Button
            size="large"
            onClick={() => {
              navigate(
                `/login?redirect=/centrali/${alloggiamentoFiltro.centrale?.id}`
              );
            }}
          >
            Apri centrale
          </Button>
        </Box>
      </>
    );
  } else {
    return <></>;
  }
}

export default function AlloggiamentoFiltroPage() {
  const params = useParams();
  const tokenCodiceQR = params.id ?? "";

  const { isLoading, error, data } = useGetAlloggiamentoFiltro(tokenCodiceQR);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return (
      <Box>
        <Typography color="secondary">
          C'è un problema con il QR code che hai scansionato :-(
        </Typography>
      </Box>
    );
  }

  return <AlloggiamentoFiltro alloggiamentoFiltro={data.data} />;
}
