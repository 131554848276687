import { DispositivoType } from "@/api";

const modelliMacchinario = [
  "DISOLEATORE GFLD 3000",
  "GFL - BANCO DI FLUSSAGGIO",
  "GFL - CENTRALE STOCCAGGIO OLIO",
  "GFL - CENTRALINA PER EMULSIONE",
  "GFL D 1000",
  "GFL D 3000",
  "GFL DF 12000",
  "GFL HC 400",
  "GFL HCF 100",
  "GFL HCF 200",
  "GFL HCF 300",
  "GFL HCFT 200",
  "GFL HCFT 400",
  "GFL HCFTA 200",
  "GFL TFL 500",
  "GFL TFL 700",
  "GFL TFLC 500",
  "GFL TFLD 500",
  "GFL TFLD 550",
  "GFL TFLE 500",
  "GFL TFLG 700",
  "GFL TFLP 500",
  "GFL TFLV 300",
  "GFL TH 2000",
  "GFL TH 4000",
  "GFL ZFM 100",
] as const;

const modelliSensori = [
  "GFL SENSOR PT",
  "GFL SENSOR WT",
  "GFL MONITORING WT",
  "GFL PARTICLE_MONITORING WT",
] as const;

export const modelliDispositiviByType: {
  [tipo in DispositivoType]:
    | []
    | typeof modelliMacchinario
    | typeof modelliSensori;
} = {
  unknown: [],
  macchinario: modelliMacchinario,
  sensore: modelliSensori,
};

export const immaginiByModello: {
  [modello in
    | (typeof modelliMacchinario)[number]
    | (typeof modelliSensori)[number]]?: string;
} = {
  "GFL - CENTRALE STOCCAGGIO OLIO": "/immagini-dispositivi/1.jpg",
  "GFL - BANCO DI FLUSSAGGIO": "/immagini-dispositivi/1.jpg",
  "GFL DF 12000": "/immagini-dispositivi/2.jpg",
  "GFL TFLV 300": "/immagini-dispositivi/3.png",
  "GFL SENSOR WT": "/immagini-dispositivi/4.png",
  "GFL MONITORING WT": "/immagini-dispositivi/5.png",
  "GFL D 3000": "/immagini-dispositivi/6.png",
  "GFL TFLD 550": "/immagini-dispositivi/7.png",
  "GFL TH 4000": "/immagini-dispositivi/8.jpg",
  "GFL PARTICLE_MONITORING WT": "/immagini-dispositivi/9.jpg",
  "GFL SENSOR PT": "/immagini-dispositivi/10.jpg",
};
